
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.footerExtraLink {
  color: $paper;
  text-decoration: none !important;
  display: inline-block;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  padding: 0 8px;
}

.footerExtraLink:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


@media (min-width: $breakpointMD) {
  .footerExtraLink {
    font-size: 16px;
    line-height: 24px;
  }
}