
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.footerBottomExtra {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.footerBottomExtraBackToTop {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 10px;
}

.footerBottomExtraBackToTopIcon {
  margin-top: 1px;
  color: $paper;
}

.footerBottomExtraLegal {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.footerBottomExtraCopyright {
  text-align: right;
  color: $paper;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.footerBottomEasterEggContainer {
  margin-left: 6px;
  height: 20px;
}

.footerBottomEasterEgg {
  height: 100%;
  width: 12.3px;
}

.footerLinksAnimation {
  position: relative;
}

.footerLinksAnimation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $paper;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footerLinksAnimation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (min-width: $breakpointSM) {
  .footerBottomExtra {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footerBottomExtraLegal {
    width: 200px;
  }
}

@media (min-width: $breakpointMD) {
  .footerBottomExtra {
    padding: 20px 50px;
  }

  .footerBottomExtraCopyright {
    font-size: 16px;
    line-height: 24px;
  }


  .footerBottomExtraLegal {
    width: auto;
  }
}
