/**
* @ref: https://stackoverflow.com/questions/60951575/next-js-using-sass-variables-from-global-scss
* this file is loaded via the next.config.js to ensure that the variables are accessible in all `.scss` files
*/

$breakpointXL: 1800px;
$breakpointLG: 1400px;
$breakpointMD: 960px;
$breakpointSM: 600px;
$breakpointXS: 450px;

:export {
  breakpointXL: $breakpointXL;
  breakpointLG: $breakpointLG;
  breakpointMD: $breakpointMD;
  breakpointSM: $breakpointSM;
  breakpointXS: $breakpointXS;
}
