
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.container {
  background-color: transparent;
}

.toggleButtonCommon {
  display: flex;
  gap: 8px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  color: $grey750;
  line-height: 24px;
  padding: 0;
  padding-bottom: 8px;
  height: 34px;

  &:global(.Mui-selected) {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}

.leftTab {
  padding-right: 10px;
}

.rightTab {
  padding-left: 10px;
}

.borderBottomBlack {
  border-color: $grey800;
}

.borderBottomDark {
  border-color: $grey750;
}

.textColor {
  color: $grey750;

  &:global(.Mui-selected) {
    color: $grey800;
  }
}
