
      @import "variables.scss";
      @import "mixins.scss";
    
.listingCard {
  position: relative;
  //background-color: var(--primary-dark);
  overflow: hidden;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 12%), 0 0px 1px 0 rgb(0 0 0 / 12%);
  cursor: pointer;
  height: 100%;
  border-radius: var(--borderRadiusSmall) !important;
  overflow: hidden;
  margin: 0px 2px;
  height: 340px;
}

.listingCardHover {
  &:hover {
    .listCardDataContainer {
      top: 112px;
    }

    .listCardDataContainerWithoutDescription {
      top: 188px;
    }

    .listCardTitle {
      font-size: 24px;
      line-height: 1.4;
    }
  }
}

.listCardImgContainer {
  width: 100%;
  height: 340px;
  max-height: 100vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.listCardDataContainerCommonOffsetTop {
  top: 242px;
}

.listCardDataContainerWithoutDescription {
  @extend .listCardDataContainerCommonOffsetTop;
}

.listCardDataContainer {
  @extend .listCardDataContainerCommonOffsetTop;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: 100%;
  transition: top 0.2s ease-in;
}

.listCardPrice {
  background-color: var(--primary-dark);
  padding: 4px 8px;
  white-space: nowrap;
  border-bottom-right-radius: var(--borderRadiusSmall);
}

.listCardDataDetails {
  padding: 8px 16px 10px;
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: inherit;
}

.listCardDataDetailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.listCardDataDetailsType {
  padding-bottom: 2px;
  font-weight: 300;
}

.listCardBreakLine {
  margin: 4px 0px;
  opacity: 0.4;
}

.hideLinkStyles {
  text-decoration: none;
}

.listCardImgContent {
  position: absolute;
  display: flex;
  z-index: 4;
}

.listCardExclusive {
  background-color: var(--primary-dark);
  padding: 4px 8px;
  white-space: nowrap;
  border-bottom-right-radius: var(--borderRadiusSmall);
}

.listCardExtraDetails {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.listCardExtraDetailsGroup {
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-200);
  padding-top: 5px;
}

.listCardLocation {
  height: 20px;
}

.listCardTextLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 300;
}

.listCardTitle {
  font-size: inherit;
  line-height: inherit;
  padding-top: 5px;
  font-weight: 500;
}

.listCardCapitalize {
  text-transform: capitalize;
}

.listCardDescription {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  font-weight: 300;
  width: 100%;
}

.buttonWrapper {
  width: 100%;
  text-transform: capitalize;
}

.buttonWrapperNoDescription {
  margin-top: -8px;
}
