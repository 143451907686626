
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.imgFromCDNError {
  width: 100%;
  height: 100%;
  background-color: $secondaryLight;
}
