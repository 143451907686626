
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.listingCard {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
  border-radius: 4px;
  margin: 0px 2px;
  height: 340px;
  box-shadow: 5px 5px 15px 5px $grey300;

  &.smallerHeight {
    height: 285px;
    margin: 0;
  }
}

.hidden {
  display: none;
}

.listCardImgContainer {
  width: 100%;
  height: 245px;
  max-height: 100vw;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-size: cover;
  background-position: center;

  &.isSmallCard {
    height: 190px;
  }
}

.listCardDataContainer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: 100%;
  transition: top 0.2s ease-in;
}

.listCardPrice {
  background-color: var(--primary-dark);
  padding: 4px 8px;
  white-space: nowrap;
  border-bottom-right-radius: var(--borderRadiusSmall);
}

.listCardDataDetails {
  padding: 8px 16px 10px;
  background-color: $paper;
  display: flex;
  flex-direction: column;
  height: inherit;
}

.listCardDataDetailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.listCardDataDetailsType {
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 12px;
}

.listCardBreakLine {
  margin: 8px 0px;
  background: rgba(69, 80, 100, 0.2);
}

.hideLinkStyles {
  text-decoration: none;
}

.listCardImgContent {
  position: absolute;
  display: flex;
  z-index: 4;
}

.listCardExclusive {
  background-color: var(--primary-dark);
  padding: 4px 8px;
  white-space: nowrap;
  color: $paper;
  border-bottom-right-radius: var(--borderRadiusSmall);
}

.listCardExtraDetails {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.listCardExtraDetailsGroup {
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: flex-start;
  align-items: center;
  color: $primary900;

  .listCardTextLine {
    color: $grey700;
  }
}

.listCardLocation {
  height: 20px;
}

.listCardTextLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.listCardTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.listingCarousel {
  .navIcon {
    top: 115px;

    &.isSmallCard {
      top: 90px;
    }
  }
}

.listCardCapitalize {
  text-transform: capitalize;
}

.buttonWrapper {
  width: 100%;
  text-transform: capitalize;
}

.buttonWrapperNoDescription {
  margin-top: -8px;
}

@media (max-width: $breakpointXS) {
  .listCardImgContainer {
    &.isSmallCard {
      height: 165px;
    }
  }
}

// popular listing card
.popularListingCard {
  box-shadow: none;
  border-radius: 0%;
  width: 100%;
  height: 260px;
  margin-bottom: 16px;

  & > a,
  & .listingCard {
    height: 100%;
    width: 100%;
    display: block;
    box-shadow: none;
    border-radius: 0%;
  }

  & .listCardImgContainer {
    border-radius: 0%;
    height: 165px;
  }

  & .listCardDataDetails {
    border: 1px solid $grey150;
    border-top: none;
  }

  @media (min-width: calc($breakpointMD + 1px)) {
    max-width: 173px;
    min-width: 173px;
    height: 100%;
    margin-bottom: 0;

    & .listCardBreakLine,
    & .listCardExtraDetails {
      display: none;
    }

    & .listCardImgContainer {
      height: 131px;
    }

    & .isSmallCard.navIcon {
      top: 50px;
    }

    & .listCardDataDetails {
      border: 1px solid $grey300;
      height: 56px;
      border-top: none;
    }

    & .listCardDataDetailsType {
      font-weight: 400;
      font-size: 12px;
      width: 100%;
      color: $dark300;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    & .listCardTextLine {
      font-weight: 600;
      font-size: 12px;
    }
  }
}
