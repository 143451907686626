
      @import "variables.scss";
      @import "mixins.scss";
    
.tag {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  background-color: var(--secondary-light);
  padding: 6px 12px;
  border-radius: var(--borderRadiusSmall);
}

.tagTitle {
  white-space: nowrap;
}
