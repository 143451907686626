//https://www.color-hex.com/color-palette/178
// paper & background
$paper: #ffffff;

// primary
$primaryLight: #3c4248;
$primaryMain: #272834;
$primaryDark: #000014;
$primary200: #485560;
$primary800: #030405;
$primary900: #000;

// secondary
$secondaryLight: #d3e8ff;
$secondaryMain: #2e54eb;
$secondaryDark: #3949ab;
$secondary50: #E7EBEF;
$secondary100: #f5f7f9;
$secondary150: #E9EFFC;
$secondary200: #9fa8da;
$secondary250: #babdc1;
$secondary270: #ECF5FF;
$secondary300: #d7dee5;
$secondary400: #5387f4;
$secondary500: #205cdf;
$secondary800: #283593;

// success Colors
$successLight: #b9f6ca;
$success50: #eaf7e7;
$success100: #f0f7e7;
$success200: #69f0ae;
$success300: #009639;
$successMain: #00e676;
$successDark: #00c853;

// error
$error50: #fde6e6;
$error700: #ea0000;
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey150: #eff2f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #bdbdbd;
$grey500: #9e9e9e;
$grey600: #646363;
$grey650: #666;
$grey700: #616161;
$grey750: #b2bac9;
$grey800: #667593;
$grey850: #2c2c2c;
$grey900: #212121;

/**
  * dark theme variants
  */

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4
$dark300: #455064;

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #eeedfd;
$darkPrimaryMain: #7267ef;
$darkPrimaryDark: #6a5fed;
$darkPrimary200: #b9b3f7;
$darkPrimary800: #554ae8;

// secondary dark
$darkSecondaryLight: #eeedfd;
$darkSecondaryMain: #7267ef;
$darkSecondaryDark: #6a5fed;
$darkSecondary200: #b9b3f7;
$darkSecondary800: #554ae8;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

/**
  * export variables to javascript
  */

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary100: $secondary100;
  secondary200: $secondary200;
  secondary500: $secondary500;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  successMain: $successMain;
  successDark: $successDark;
  success200: $success200;
  success300: $success300;

  // error
  error700: $error700;
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey150: $grey150;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey800: $grey800;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;
  dark300: $dark300;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}
