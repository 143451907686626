
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.linkTo {
  text-decoration: none;
  color: $secondaryMain;
  display: inline-block;
  max-width: 180px;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
