@mixin for-tablet-portrait-up {
  @media (min-width: $breakpointSM) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: $breakpointMD) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $breakpointLG) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: $breakpointXL) {
    @content;
  }
}
