
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.buttonsContainer {
  color: $grey300;

  .commonSideButtonStyles {
    position: absolute;
    z-index: 999;
    top: 52%;
    width: 48px;
    height: 48px;
    background-color: $secondary300;
    cursor: pointer;
  }

  .leftButton {
    @extend .commonSideButtonStyles;

    left: -20px;
  }

  .rightButton {
    @extend .commonSideButtonStyles;

    right: -20px;
  }
}

@media (max-width: $breakpointLG) {
  .buttonsContainer {
    .commonSideButtonStyles {
      width: 38px;
      height: 38px;
    }

    .leftButton {
      left: -16px;
    }

    .rightButton {
      right: -16px;
    }
  }
}
