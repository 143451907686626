
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.tagLabel {
  background-color: $secondary100;
  color: $dark300;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  &.selected, &:hover {
    background-color: $secondary150;
    color: $secondary500;
  }
}
