
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.dragBarContainer {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 16px 0;
}

.dragBar {
  background-color: $secondaryLight;
  height: 3px;
  width: 10%;
}
