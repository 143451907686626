
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.listingGrid {
  width: 100%;
  height: fit-content;
  padding: 0 0 32px;

  @media (max-width: $breakpointXL) {
    max-height: inherit;
    width: 60%;
  }

  @media (max-width: $breakpointMD) {
    width: 100%;
  }

  @media (min-width: $breakpointMD) {
    &:global(.map) {
      height: 100%;
      max-width: 60%;
    }
  }
}

.listingGrid:global(.map) {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  overflow-y: scroll;
  margin-bottom: 0px !important;
  height: 100%;

  @media (max-width: $breakpointMD) {
    width: 100%;
  }
}

.noResultsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;

  @media (min-width: $breakpointMD) {
    min-height: calc(100vh - 200px);
    gap: unset;
    margin-top: 0;
  }

  :global(.venue) {
    :global(.popular) {
      width: 100%;
      max-width: calc(100% - 2px);
    }
  }
}
