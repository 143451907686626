
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.container {
  background-color: transparent;

  .toggleButtonGroup .toggleButtonCommon {
    display: flex;
    gap: 8px;
    height: 32px;
    padding: 8px;
    border-radius: 0;
    border-color: $grey750;
    color: $grey800;
    background-color: $paper;
  }
}
