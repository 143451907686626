
      @import "variables.scss";
      @import "mixins.scss";
    
.splitLayoutFormContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.splitLayoutFormTitle,
.splitLayoutFormChildren {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
}

.splitLayoutFormChildren {
  text-align: left !important;
}

.splitLayoutFormBlaceLogo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.backToSearchLink {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  color: black;
}

.backToSearchLink:hover {
  text-decoration: underline;
}

@media (max-width: $breakpointSM) {
  .splitLayoutFormContainer {
    gap: 20px;
    max-width: 100%;
  }
}

@media (max-width: $breakpointXS) {
  .splitLayoutFormTitle {
    padding-top: 0px;
  }

  .splitLayoutFormTitlePadding {
    padding-top: 30px;
  }
}

@media (max-height: 880px) {
  .splitLayoutFormContainer {
    gap: 12px !important;
  }

  .splitLayoutFormBlaceLogo {
    display: none;
  }

  .backToSearchLink {
    padding-top: 4px;
  }
}
