
      @import "variables.scss";
      @import "mixins.scss";
    
.adCard {
  width: 100%;
  height: 100%;
  //padding: 16px 12px;
  min-height: 340px;
  max-height: 340px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 12%), 0 0px 1px 0 rgb(0 0 0 / 12%);
  border-radius: var(--borderRadiusSmall) !important;
  overflow: hidden;
  position: relative;
}

.adCardInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: var(--borderRadiusSmall) !important;
  background: linear-gradient(
    90deg,
    rgba(95, 152, 240, 0.7) 4.69%,
    var(--secondary-main) 94.33%
  );
  border-radius: var(--borderRadiusSmall);
}

.adCardInnerImage {
  width: 50%;
  min-width: 50%;
  height: 100%;
  border-radius: var(--borderRadiusSmall);
  overflow: hidden;
}

.adCardInnerImage > div > div,
.adCardInnerImage > div {
  height: 100% !important;
  border-radius: var(--borderRadiusSmall);
  overflow: hidden;
}

.adImage {
  height: 340px;
  width: 480px;
  overflow: hidden;
}

.adCardInnerAbout {
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 40px;
  gap: 24px;
  justify-content: space-between;
}

.adCardInnerAboutDetails {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

//these are the same as the media tag
.adCardInner:global(.map) {
  flex-direction: column;
}

.adCardInnerImage:global(.map) {
  width: 100%;
  min-width: 100%;
  inset: 0px;
  position: absolute;
  z-index: -2;
}

.adCardInnerAbout:global(.map) {
  position: absolute;
  z-index: 2;
  inset: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(4px);
}

.adLink {
  text-decoration: none;
  width: 100%;
}

.adLink button {
  width: 100%;
}

//this is the same point in ListGrid.module.scss that grid changes to 2 x 1fr
@media (max-width: $breakpointLG) {
  .adCardInner {
    flex-direction: column;
  }

  .adCardInnerImage {
    width: 100%;
    min-width: 100%;
    inset: 0px;
    position: absolute;
    z-index: -2;
  }

  .adCardInnerAbout {
    position: absolute;
    z-index: 2;
    inset: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(4px);
  }
}
