
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.listingHeader {
  border-bottom: 1px solid var(--grey-300);
  position: relative;
  overflow: visible;
  height: 80px;
}

.listingHeaderInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px 12px 12px;

  @media (max-width: $breakpointXL) {
    padding: 20px 16px;
  }

  @media (max-width: $breakpointMD) {
    max-width: 100vw;
    overflow: scroll;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.headerButtonsContainer {
  display: flex;
  gap: 32px;

  @media (max-width: $breakpointMD) {
    width: 100%;
    justify-content: space-between;
  }
}

.quickFiltersContainer {
  display: block;
  width: calc(60% - 16px);

  @media (max-width: $breakpointMD) {
    display: none;
  }
}
