
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.mapTurnOnOffBttnContainer {
  position: relative;
}

.mapTurnOnOffBttnInner {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: var(--zIndexAboveMapBttn);
}

.markerPinHovered {
  transform: scale(1.3);
}

.detailsMap {
  width: 100%;
  height: 100%;
}

.toggleMapBtn {
  display: flex;
  gap: 8px;
  height: 32px;
  padding: 8px;
  border-radius: 0;
  transition: transform 0.3s;
  font-size: 12px;
  background-color: $primaryDark;
  color: $paper;

  &:hover {
    background-color: $primaryDark;
    transform: scale(1.1);
  }

  @media (min-width: $breakpointMD) {
    height: 42px;
    padding: 16px;
    font-size: 16px;
  }
}
