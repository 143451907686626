
      @import "variables.scss";
      @import "mixins.scss";
    
.clampBox button {
  justify-content: flex-start;
}

.clampLines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.buttonPaddingTop {
  padding-top: 12px;
}

.readMoreButton {
  padding: 0 !important;
}
