
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.baseSelect {
  & :global(.MuiSelect-select) {
    padding: 0;
    font-size: 12px;
    color: $dark300;

    input {
      font-size: 12px;
      padding: 15.5px 14px;
      padding-left: 4px;
      color: $dark300;

      &::placeholder {
        color: $grey800;
        font-size: 12px;
        color: $grey800;
        opacity: 1;
      }
    }

    &:focus-visible {
      outline: none;
    }
  }
}
