
      @import "variables.scss";
      @import "mixins.scss";
    
.inquiryForm {
  position: relative;
  height: 100%;
}

.inquirySubmitted {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inquiryFormSeller {
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.inquiryFormSellerAvatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inquiryFormSellerAvatar > div {
  font-size: 42px;
}

.inquiryFormSellerInfo {
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.inquiryFormPricing {
  padding: 12px 0 0;
  text-align: center;
}

.inquiryFormContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
}

.inquiryFormFormSplit {
  display: flex;
  gap: 8px;
}

.inquiryFormFormSplit input {
  font-size: 16px !important;
  height: 23px;
}

.inquiryFormFormSplit input[type="number"]::-webkit-inner-spin-button,
.inquiryFormFormSplit input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inquiryFormFormSplit > div {
  width: 100%;
}

.inquiryFormFormSplit > div > div {
  width: 100%;
}

.inquiryFormCheckbox {
  padding: 0px;
  margin-top: -12px;
  margin-bottom: -12px;
}

.inquiryFormRespondsIn {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px;
  width: 100%;
}

.inquiryFormContainerCard {
  display: none;
}

.inquiryFormCardInfo {
  padding: 12px 24px 24px;
}

@media (max-width: $breakpointMD) {
  .inquiryFormContainerCard {
    display: unset;
    width: 100%;
    margin-top: 6px;
    border: 1px solid var(--grey-300);
    border-radius: var(--borderRadiusSmall);
    overflow: none;
  }
}

.createInquiryFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px;
}

.createInquiryFooterPrice {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.createInquiryFooterPricePrefix {
  padding-top: 3px;
  padding-right: 6px;
}
