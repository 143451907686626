
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.listingPagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.listingPagination:global(.fixed) {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 99;
  background: $paper;
}
