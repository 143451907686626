
      @import "variables.scss";
      @import "mixins.scss";
    
.shareDetailsDialog {
  width: 400px;
  max-width: calc(100vw - 64px);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shareDetailsDialog button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
}

.shareDetailsDialogLink {
  text-decoration: none !important;
}

.shareDetailsDialogContainer {
  height: unset !important;
  margin-top: 160px;
}

.shareDetailsImageOverlay {
  position: relative;
}

.shareDetailsImageOverlayInner {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  backdrop-filter: blur(60px);
  background-color: rgba(0, 0, 0, 0.18);
}
