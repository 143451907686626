
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.error {
  color: $error700;
}

.success {
  color: $success300;
}
