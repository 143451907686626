
      @import "variables.scss";
      @import "mixins.scss";
    
.globalSearchFieldGroup {
  padding: 0px;
  width: 85%;
  transition: 0.3s ease;

  @media (min-width: $breakpointSM) {
    width: 100%;
  }
}

.globalSearchInput {
  border: none;
  border-radius: 50px !important;
}

.globalSearchInput > div {
  padding: 2px 16px !important;
  border-radius: 50px !important;
  background-color: var(--grey-800);
  color: var(--common-white);
}

.globalSearchInput > div > div {
  color: var(--common-white);
}

.globalSearchInput input {
  padding: 12px;
  width: 100%;
  text-transform: capitalize;
  background-color: inherit;
  color: inherit;
  height: 44px;
  box-sizing: border-box;
}

.globalSearchInput input::placeholder {
  color: var(--grey-100);
}

.globalSearchInput fieldset {
  border: none;
}

.globalSearchDrawerContainer {
  padding-bottom: 80px;
}

.globalSearchDrawerContainer::-webkit-scrollbar {
  display: none;
}

.globalSearchDrawerInner {
  padding: 12px;
}

.globalSearchDrawerLabel {
  padding: 0px 12px;
  display: flex;
  justify-content: center;
}

.globalSearchDrawerClose {
  position: fixed;
  bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2000;
}

.autoCompleteDropDown {
  width: 100%;
  height: 400px;
}

.autoCompleteDropDownPopper {
  text-transform: capitalize;
  width: 100%;
}

.globalSearchSearchIconDefault {
  color: var(--grey-500) !important;
}

.globalSearchSearchIconActive {
  color: var(--common-white) !important;
}

.clearSearchButton {
  max-width: fit-content;
  min-width: fit-content;
  padding: 0;
}
