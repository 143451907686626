
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.footerContactInfoItem {
  display: flex;
  gap: 12px;

  a {
    font-size: 12px;
    white-space: wrap;
    width: 50%;
  }
}

.footerContactInfoItemIcon {
  margin-top: -2px;
}

@media (min-width: 500px) and (max-width: $breakpointMD) {
  .footerContactInfoItem {
   justify-content: center;
  }
}

@media (min-width: $breakpointMD) {
  .footerContactInfoItem  {
    a {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
