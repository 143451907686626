
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.footerLinksText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.footerLinksTextLanding {
  font-size: 12px;
  line-height: 16px;
  margin-left: -12px;
}

.footerLinksAnimation {
  color: $paper;
  text-decoration: none !important;
  display: inline-block;
  position: relative;
}

.footerLinksAnimation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $paper;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footerLinksAnimation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (min-width: 500px) and (max-width: $breakpointMD) {
  .itemStyles {
    justify-content: center;
  }
}

@media (min-width: $breakpointMD) {
  .footerLinksText {
    font-size: 24px;
    line-height: 33.6px;
  }

  .footerLinksTextLanding {
    font-size: 18px;
    line-height: 32px;
  }
}
