
      @import "variables.scss";
      @import "mixins.scss";
    
.signinFormEmail {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.signinFormBttnInner {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.buttonLink {
  width: 100%;
  text-decoration: none;
}

.linkBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkBox a {
  text-decoration: none;
  color: var(--secondary-main);
}

.linkBox a:hover {
  text-decoration: underline;
}
