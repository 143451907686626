
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.pinPreview {
  padding: 0;
  width: 60vw;

  .previewContent {
    position: relative;
  }

  .closeIconWrapper {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 30px;
    background-color: rgba($grey150, 0.9);
    cursor: pointer;

    .closeIcon {
      color: $grey800;
    }
  }
}

@media (min-width: $breakpointSM) {
  .pinPreview {
    width: 320px;
  }
}
