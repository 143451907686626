
      @import "variables.scss";
      @import "mixins.scss";
    
.listingAttrSection {
  border-bottom: 1px solid var(--grey-200);
}

.listingAttrTitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 4px 12px 16px;
  cursor: pointer;
}

.listingAttrTitleContainerInner {
  display: flex;
  gap: 6px;
}

.listingAttrTitleTxt {
  font-weight: 600;
}

.listingAttrOptionsContainer {
  padding: 4px 16px 16px;
}

.listingAttrOptions {
  cursor: pointer;
  display: flex;
}

.listingAttrOptionsSlider {
  padding: 20px;
}

.listingAttrOptionsExpandButton {
  padding-left: 24px;
}

.filterOptionSubtitle {
  font-size: 11px;
  margin-top: -2px;
  margin-bottom: 2px;
  padding: 0px;
  color: var(--grey-600);
}

.listingAttrFiltersNotAvailable {
  padding-left: 16px;
  padding-right: 16px;
}

.filterCountRel {
  position: relative;
  width: 0px;
  height: 0px;
}

.filterCountAbs {
  position: absolute;
  top: -2px;
  left: 0;
  background-color: var(--secondary-light);
  color: var(--common-black);
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
