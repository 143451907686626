
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.tagSection {
  .sectionHeader {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .sectionTitle {
    color: $dark300;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
  }

  .tagList {
    margin-top: 16px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }

  .selectedTagsCount {
    color: $grey800;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .expandIconWrapper {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }

  .expandIcon {
    color: $grey800;
    font-size: 28px;
  }

  .showMoreBtn {
    color: $secondary500;
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 32px;
  }
}
