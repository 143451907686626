
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  height: 50%;

  @media (min-width: $breakpointMD) {
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .noResultsTitle {
    font-weight: 600;
    font-size: 12px;
    color: $grey800;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .resetFiltersButton {
    width: 279px;
    height: 40px;
    background-color: $grey150;
    border: none;
    font-weight: 500;
    font-size: 12;
    color: $grey800;
    font-family: Poppins;
    margin-top: 20px;
  }

  .businessImage {
    width: auto;
    height: 50%;
  }
}
