
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.popularListingsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding: 0px 24px 10px 24px;

  @media (min-width: $breakpointMD) {
    height: 50%;
    justify-content: end;
    padding-bottom: 40px;
  }

  .popularListingsTitle {
    font-size: 16px;
    font-weight: 600;
    color: $dark300;
    margin-bottom: 16px;
    text-align: center;
  }

  .popularListingsListWrapper {
    @media (min-width: $breakpointMD) {
      display: flex;
      justify-content: center;
    }
  }

  .popularListingsList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: calc($breakpointMD + 1px)) {
      flex-direction: row;
      gap: 24px;
      overflow-x: scroll;
      height: 202px;
      padding-bottom: 15px;
    }

    :global(.popular) {
      a > div {
        margin: 0 !important;
      }
    }
  }
}
