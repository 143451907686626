
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.listingCount {
  color: $dark300;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
