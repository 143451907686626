
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.footer {
  background: $primaryDark;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  position: relative;
}

.footerTop {
  padding: 40px 20px 20px;
  display: flex;
  flex-direction: column;
}

.footerLogo {
  margin-bottom: 10px;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
}

.footerLinks {
  margin-top: 15px;
  margin-left: -12px;
}

.footerSocialLinks {
  display: flex;
  padding: 20px 0 50px;
}

.footerBottom {
  padding-bottom: 0px;
}

.footerDivider {
  opacity: 30%;
  width: calc(100% + 40px);
  margin: 0 -20px;
}

@media (min-width: 500px) and (max-width: $breakpointMD) {
  .footerLogo {
    text-align: center;
  }
  .linksWrapper {
    display: flex;
    align-items: center;
  }

  .footerSocialLinks {
    justify-content: center;
  }
}

@media (min-width: $breakpointMD) {
  .footer {
    position: static;
  }
  
  .footerTop {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .landingList {
    width: 100%;
    margin-bottom: 40px;
    order: 1;
  }

  .footerLogo {
    width: 50%;
    padding-left: 40px;
    order: 2;
  }

  .linksWrapper {
    width: 50%;
    margin-bottom: 30px;
    order: 3;
  }

  .footerLinks {
    margin-left: 0;
    margin-top: -16px;
    width: 100%;
  }

  .signupWrapper {
    order: 4;
    width: 100%;
    padding: 0 40px;
  }

  .footerSocialLinks {
    width: 100%;
    order: 5;
  }
}

@media (min-width: 1200px) {
  .footerLogo {
    width: 25%;
  }

  .linksWrapper {
    width: 25%;
  }

  .signupWrapper {
    width: 50%;
  }

  .footerSocialLinks {
    margin-top: 20px;
  }
}
