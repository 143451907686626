
      @import "variables.scss";
      @import "mixins.scss";
    
.inquiryForm {
  position: relative;
  height: 100%;
}

.inquiryFormContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
}

.inquiryFormContainer input[type="number"]::-webkit-inner-spin-button,
.inquiryFormContainer input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
