:root {
  /** 
    * z-index
    */
  --zIndexAboveMapBttn: 30;
  --zIndexDetailsTabsBarMobile: 160;
  --zIndexDetailsTabsBarDesktop: 4;
  --zIndexNavigationBarDesktop: 151;
  --zIndexGlobalSearchDrawer: 150;
  --zIndexDesktopSecondaryNav: 20;
  --zIndexImageGridOverlay: 180;
  --zIndexDetailInquiryFooter: 130;
  --zIndexImageGridCloseIcon: 2000;
  --zindexVenueDetailMap: 5000;

  /** 
    * padding
    */
  --paddingSmall: 12px;
  --paddingMedium: 24px;

  /** 
    * border radius
    */
  --borderRadiusSmall: 2px;

  /** 
  * colors - these are automaticaly filled in my MUI theme
  * WARNING: DO NOT ADD ANY OTHER COLORS HERE - MUST BE DONE IN MUI PALETTE
  */
  --common-black: #111936;
  --common-white: rgb(255, 255, 255);
  --primary-200: #9fa8da;
  --primary-800: #283593;
  --primary-light: #e8eaf6;
  --primary-main: #3f51b5;
  --primary-dark: #3949ab;
  --primary-contrastText: #fff;
  --secondary-200: #9fa8da;
  --secondary-800: #283593;
  --secondary-light: #e8eaf6;
  --secondary-main: #3f51b5;
  --secondary-dark: #3949ab;
  --secondary-contrastText: #fff;
  --error-light: #ef9a9a;
  --error-main: #f44336;
  --error-dark: #c62828;
  --error-contrastText: #fff;
  --orange-light: #fbe9e7;
  --orange-main: #ffab91;
  --orange-dark: #d84315;
  --warning-light: #fff8e1;
  --warning-main: #ffe57f;
  --warning-dark: #ffc107;
  --warning-contrastText: rgba(0, 0, 0, 0.87);
  --success-200: #69f0ae;
  --success-light: #b9f6ca;
  --success-main: #00e676;
  --success-dark: #00c853;
  --success-contrastText: rgba(0, 0, 0, 0.87);
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #616161;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;
  --dark-800: #1a223f;
  --dark-900: #111936;
  --dark-light: #bdc8f0;
  --dark-main: #29314f;
  --dark-dark: #212946;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
}

body::-webkit-scrollbar {
  display: none;
}

textarea:focus,
input:focus {
  outline: none;
}

button,
a {
  cursor: pointer;
}

textarea:focus,
input:focus {
  outline: none;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
  border-radius: 4px;
}
