
      @import "variables.scss";
      @import "mixins.scss";
    
.dataCaptureExitContainer {
  position: relative;
}

.dataCaptureExitInner {
  position: absolute;
  top: -12px;
  right: 0px;
  color: var(--common-black);
}
