
      @import "variables.scss";
      @import "mixins.scss";
    
.splitLayout {
  --bottomHeight: 136px;
  --bottomHeightWithPadding: 168px;

  width: 100%;
  max-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
}

.splitLayout::-webkit-scrollbar {
  display: none !important;
}

.splitLayoutRightContainer {
  overflow-y: scroll;
  max-height: 100vh;
}

.splitLayoutRightContainer::-webkit-scrollbar {
  display: none !important;
}

.splitLayoutFormWrapper {
  padding: 72px 148px 24px 136px;
  overflow-y: scroll;
}

.splitLayoutFormWrapper::-webkit-scrollbar {
  display: none !important;
}

.splitLayoutImgContainer {
  height: 100vh;
}

.splitLayoutImgWrapper {
  position: relative;
  height: 100%;
  min-height: 900px;
}

.splitLayoutBgCover {
  position: absolute;
  z-index: 2;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.24);
}

.splitLayoutLogoWrapper {
  position: absolute;
  top: 88px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.splitLayoutImg {
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

@media (max-width: 1480px) {
  .splitLayoutFormWrapper {
    padding: 72px 40px 0px;
  }

  .splitLayoutFormWrapper:global(.toast) {
    padding: 40px 40px 0px;
  }
}

@media (max-width: $breakpointMD) {
  .splitLayout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
    min-height: unset;
    max-height: unset;
  }

  .splitLayoutRightContainer {
    max-height: unset;
  }

  .splitLayoutFormWrapper {
    width: 100%;
    padding: 24px 16px var(--bottomHeightWithPadding) 16px;
  }

  .splitLayoutFormWrapper:global(.toast) {
    width: 100%;
    padding: 24px 16px var(--bottomHeightWithPadding) 16px;
  }

  .splitLayoutImgContainer {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    right: 0;
    height: var(--bottomHeight);
    overflow: hidden;
    -webkit-box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12);
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12);
  }

  .splitLayoutImgWrapper {
    position: relative;
    height: var(--bottomHeight);
    min-height: unset;
  }

  .splitLayoutLogoWrapper {
    display: flex;
    height: 100%;
    top: unset;
    justify-content: center;
    align-items: center;
  }
}
