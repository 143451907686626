
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.swiperSlide {
  width: 100% !important;
  height: 100% !important;
}

.navIcon {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: $grey150;
  position: absolute;
  z-index: 4;
  top: 115px;
  opacity: 0.7;
  display: none;
  border-radius: 30px;

  &.rightArrow {
    right: 10px;
  }

  &.leftArrow {
    left: 10px;
  }

  &:hover {
    opacity: 1;
  }
}

.listingCarousel {
  :global(.swiper-pagination) {
    display: none;
  }

  &:hover {
    .navIcon,
    :global(.swiper-pagination) {
      display: block;
    }
  }

  :global(.swiper-button-disabled) {
    opacity: 0;

    &:hover {
      opacity: 0;
    }
  }
}

.carousel {
  :global(.swiper-pagination-bullet-active) {
    background-color: $paper;
  }
}

@media (max-width: $breakpointMD) {
  .listingCarousel {
    .navIcon,
    &:hover .navIcon {
      display: none;
    }

    :global(.swiper-pagination) {
      display: block;
    }
  }
}
