
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.commonAlertStyles {
  display: flex;
  justify-content: center;
  align-items: center;

  & :global .MuiAlert-message {
    max-width: 80vw;
    text-align: center;
  }
}

.alertCustomSuccessStyles {
  background-color: $success100;
}

.alertCustomErrorStyles {
  background-color: $error50;
  color: $error700;
}

.alertCustomInfoStyles {
  background-color: $secondaryLight;
  color: $secondary500;
}
