
      @import "variables.scss";
      @import "mixins.scss";
    
@import "@/styles/scss/theme.module.scss";

.linkTo {
  text-decoration: none;
  color: $secondaryMain;
}
