
      @import "variables.scss";
      @import "mixins.scss";
    
@media (max-width: $breakpointMD) {

  .listingModal {
    &:global(.open) {
      top: auto !important;
      position: unset !important;
    }

    &:global(.closed) {
      &:global(.MuiPaper-root) {
        top: calc(100% - 60px);
      }
    }

    &:global(.MuiPaper-root) {
      position: absolute;
      width: 100%;
      top: calc(100% - 280px);
      z-index: 2;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
